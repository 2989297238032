<template>
  <section id="events-list" class="route">
    <h1 class="title">
      One On <span class="accent--text">One</span>
      <i class="fas fa-arrow-right"></i>
      <hr class="divider divider--vertical" />
      <span class="accent--text">Details</span>
    </h1>
  </section>
</template>

<script>
export default {
  name: "ViewOneOnOne"
};
</script>
